import * as React from "react"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

const SolutionsList = ({ solutions }) => (

  <Row xs={1} sm={2} lg={3} className='component-solutions-list'>
      {solutions.map(solution =>
          <Col className='mb-4' key={solution.slug}>
            <Link to={`/solutions/${solution.slug}`} className='text-decoration-none' title={solution.name}>
              <Card className='h-100 bhk-border' bg='dark' text='white'>
                  <Card.Body className='d-flex flex-column'>
                      <Card.Title className='bhk-action-arrow text-white'>
                        {solution.name}
                      </Card.Title>
                      <Card.Text className='flex-grow-1'>
                        {solution.description.description}

                      </Card.Text>
                      <Link to={`/solutions/${solution.slug}`} className='text-right' title={solution.name}>
                        <Button as="span" variant='primary' size='sm'>Read More</Button>
                      </Link>
                  </Card.Body>
              </Card>
            </Link>
          </Col>
      )}
  </Row>
)

export default SolutionsList
