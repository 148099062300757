import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SolutionsList from "../components/solutions-list"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const Solutions = ({ data }) => {

    return (
        <Layout>
            <article>
              <h1>Solutions</h1>

              <Row className='mb-3'>
                <Col xs={12} sm={6}>
                  <p className='lead'>
                    Every organisation we've worked with has one thing in common: a reputation they depend on to continue functioning.
                  </p>
                </Col>

                <Col xs={12} sm={6}>
                  <p>
                    Our solutions are tailored to equip our clients and help them protect their personal and corporate reputations in times of crisis when their leadership and response are tested to the extreme.  These solutions will be adapted to the characteristics, personality, operational environment and needs of each individual client.
                  </p>
                </Col>
              </Row>

              {data.allContentfulPillar.nodes.map(pillar => <div key={pillar.id}>
                <h2>{pillar.name}</h2>
                <SolutionsList solutions={pillar.solution}/>
              </div>)}

            </article>

        </Layout>
    )
}

export default Solutions;

export const query = graphql`
query MyQuery {
  allContentfulPillar(sort: {fields: index}) {
    nodes {
      id
      name
      solution {
        name
        slug
        description {
          description
        }
      }
    }
  }
}
`
